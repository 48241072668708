<template>
   <div class="content-wrapper">
      <div v-if="this.user.usertype==1">
          <TeacherDashboard></TeacherDashboard>
      </div>
     <div v-else>
          <StudentDeshbord></StudentDeshbord>
     </div>
    </div>
</template>

<script>
import TeacherDashboard from '../../components/TeacherDashboard.vue';
import StudentDeshbord from '../../components/StudentDeshbord.vue';
export default {
    computed:{
      addminurl(){
        return this.$store.state.addminurl;

      },
      url(){
        return this.$store.state.url;
      },
      token(){
      return this.$store.state.authToken;
      },
      user(){
      return this.$store.state.user;
      }

    },
    components: {

       TeacherDashboard,
       StudentDeshbord,
    },
    mounted(){
      console.log(this.user);
    }

}
</script>