import { createRouter, createWebHistory } from 'vue-router'
import Dashboardview from '../views/Dashboard/DashboardView.vue'
// import Loginview from '../views/Login-View.vue'
import store from '../store'
const routes = [
 {
    path: '/login',
    name: 'login',
    meta: { layout: 'empty' }
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboardview
  },
  {
    path: '/profile',
    name: 'profileview',
    component: () => import('../views/profile/profileView.vue')
  },

  {
    path: '/studentvedio',
    name: 'studenvedios',
    component: () => import( '../views/Dashboard/studentvideo.vue')
  },

   {
    path: '/pdf_list',
    name: 'pdf_lists',
    component: () => import('../views/Dashboard/student_pdf.vue')
  },
   
   {
    path: '/PDFfilelist',
    name: 'pdflist',
    component: () => import( '../views/Dashboard/pdf-list.vue')
  },
   {
    path: '/imagelist',
    name: 'imagelists',
    component: () => import('../views/Dashboard/image-list.vue')
  },
   {
    path: '/users',
    name: 'studentview',
    component: () => import('../views/student/studentView.vue')
  },
   {
    path: '/user-registration',
    name: 'studentviewregistion',
    component: () => import('../views/student/registration-student.vue')
  },
  {
    path: '/studetneditdata/:id',
    name: 'updatestudent',
    props:true,
    component: () => import('../views/student/edit_Student.vue')
  },


   {
    path: '/Images-upload',
    name: 'imagesupload',
    component: () => import('../views/File-upload/img-upload.vue')
  },
   {
    path: '/Imagefilelist',
    name: 'fileimg',
    component: () => import('../views/File-upload/viewimage_file.vue')
  },
  {
    path: '/Imageseditdata',
    name: 'imgedit',
    component: () => import('../views/File-upload/imgfile_edit.vue')
  },


{
    path: '/video-upload',
    name: 'videoupload',
    component: () => import( '../views/File-upload/video-upload.vue')
  },
  {
    path: '/videofile_list',
    name: 'videofile',
    component: () => import( '../views/File-upload/viewvideo_file.vue')
  },
  {
    path: '/pdf-upload',
    name: 'pdfupload',
    component: () => import('../views/File-upload/pdf-upload.vue')
  },
  {
    path: '/pdflist',
    name: 'pdfviewfile',
    component: () => import('../views/File-upload/viewpdf_file.vue')
  },

   {
    path: '/showclass',
    name: 'classone',
    component: () => import( '../views/class-list/oneView.vue')
  },

   {
    path: '/create-department',
    name: 'classcreate',
  
    component: () => import('../views/class/createView.vue')
  },
  {
    path: '/viewall-department',
    name: 'viewallclass',
    component: () => import( '../views/class/classView.vue')
  },
  {
    path: '/addfabric',
    name: 'subjecname',
    component: () => import( '../views/subject/subjectCreate.vue')
  },
  {
    path: '/fabric',
    name: 'viewsubject',
    component: () => import( '../views/subject/subjectView.vue')
  },

   {
    path: '/noticecreate',
    name: 'notice_create',
    component: () => import('../views/notice/CreateNotice.vue')
  },
  {
    path: '/noticeview',
    name: 'notice_view',
    component: () => import( '../views/notice/NoticeView.vue')
  },
  {
    path: '/addprinttype',
    name: 'addprinttype',
    component: () => import( '../views/print/CreatePrinttype.vue')
  },
  {
    path: '/printtypes',
    name: 'printtypes',
    component: () => import( '../views/print/PrinttypeView.vue')
  },
  {
    path: '/addscreen',
    name: 'addscreen',
    component: () => import( '../views/screen/CreateScreen.vue')
  },
  {
    path: '/viewscreen',
    name: 'viewscreen',
    component: () => import( '../views/screen/Viewscreen.vue')
  },
  {
    path: '/addmachine',
    name: 'addmachine',
    component: () => import( '../views/machine/CreateMachine.vue')
  },
  {
    path: '/viewmachine',
    name: 'viewmachine',
    component: () => import( '../views/machine/ViewMachine.vue')
  },










  {
    path: '/quiz',
    name: 'quiz',
    component: () => import( '../views/quiz/quiz_View.vue')
  },
  {
    path: '/createquiz',
    name: 'createquiz',
    component: () => import( '../views/quiz/quiz_Create.vue')
  },
  {
    path: '/createquizquestion',
    name: 'createquizquestion',
    component: () => import( '../views/quiz/quiz_question_Create.vue')
  },
 {
    path: '/Payments_create',
    name: 'Payments_Section',
    component: () => import( '../views/payments/Paymentcretaed.vue')
  },
   {
    path: '/PaymentsView',
    name: 'Payments_View',
    component: () => import( '../views/payments/Paymentview.vue')
  },
    {
    path: '/quizsee',
    name: 'qui_zsee',
    component: () => import( '../views/QuizCrectAns/QuizCrectAns.vue')
  },
  {
    path: '/mcq',
    name: 'mcq',
    component: () => import( '../views/quiz/mcqView.vue')
  },
  {
    path: '/question',
    name: 'question',
    component: () => import( '../views/quiz/questionView.vue')
  },
  {
    path: '/result',
    name: 'resut',
    component: () => import( '../views/reuslt/resultView.vue')
  },
  {
    path: '/buyers',
    name: 'buyers',
    component: () => import( '../views/Developmentteam/Buyers.vue')
  },
  {
    path: '/party',
    name: 'party',
    component: () => import( '../views/Developmentteam/Party.vue')
  },
  {
    path: '/marcandiser',
    name: 'marcandiser',
    component: () => import( '../views/Developmentteam/Marcandiser.vue')
  },
  {
    path: '/partyconcern',
    name: 'partyconcern',
    component: () => import( '../views/Developmentteam/Partyconcern.vue')
  },
  {
    path: '/develpmentnumber',
    name: 'develpmentnumber',
    component: () => import( '../views/Development/DevelopmentNumber.vue')
  },
  {
    path: '/incompletedevelopment',
    name: 'incompletedevelopment',
    component: () => import( '../views/Development/Incompletedevelopment.vue')
  },
  {
    path: '/incompleterndstage',
    name: '/incompleterndstage',
    component: () => import( '../views/Development/Incompleternd.vue')
  },
  {
    path: '/rndstage',
    name: 'rndstage',
    component: () => import( '../views/Development/Rndstage.vue')
  },
  
  {
    path: '/viewbasecolor',
    name: 'viewbasecolor',
    component: () => import( '../views/basecolor/ViewBaseColor.vue')
  },
  {
    path: '/createbasecolor',
    name: 'createbasecolor',
    component: () => import( '../views/basecolor/CreateBasecolor.vue')
  },

  {
    path: '/incompletedesign',
    name: 'incompleterndstage',
    component: () => import( '../views/Development/Incompletedesign.vue')
  },
  {
    path: '/pendingdesignreport',
    name: 'pendingdesignreport',
    component: () => import( '../views/Development/Pendingdesignreport.vue')
  },
  {
    path: '/completedesignreport',
    name: 'completedesignreport',
    component: () => import( '../views/Development/Completedesignreport.vue')
  },
  {
    path: '/recivedesignreport',
    name: 'recivedesignreport',
    component: () => import( '../views/Development/Recivedesignreport.vue')
  },
  

  {
    path: '/designstage',
    name: 'designstage',
    component: () => import( '../views/Development/Designstage.vue')
  },
  {
    path: '/incompleteengraving',
    name: 'incompleteengraving',
    component: () => import( '../views/Development/Incompleteengraving.vue')
  },
  {
    path: '/engravingstage',
    name: 'engravingstage',
    component: () => import( '../views/Development/Eengraving.vue')
  },
  {
    path: '/incompletesample',
    name: 'incompletesample',
    component: () => import( '../views/Development/Incompletesample.vue')
  },
  {
    path: '/sample',
    name: 'sample',
    component: () => import( '../views/Development/Sample.vue')
  },
  {
    path: '/planing',
    name: 'planing',
    component: () => import( '../views/Development/Planing.vue')
  },
  {
    path: '/completedevelopment',
    name: 'completedevelopment',
    component: () => import( '../views/Development/Completedevelopment.vue')
  },
  {
    path: '/report',
    name: 'report',
    component: () => import( '../views/Report/Report.vue')
  },
  {
    path: '/fabricreciveddate',
    name: 'fabricreciveddate',
    component: () => import( '../views/Development/FabricReceivedDate.vue')
  },
  {
    path: '/developmentstatus',
    name: 'developmentstatus',
    component: () => import( '../views/Development/Developmentstatus.vue')
  },

  

  
  

  


  



  

  

  

  


  

  
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (store.getters.isAuthenticated && to.name === 'login') {
    next({ path: '/' }); 
  }
  else if(!store.getters.isAuthenticated && to.name !=='login') {
    next({ name: 'login' });
  }
  else {
    next(); 
  }
});

export default router








