<template>
	   <div class="row">
<!--             <div class="col-md-6 col-lg-4 grid-margin stretch-card">
              <div class="card cards">
                  <router-link  class="menu-title " to="/studentvedio" style="text-decoration: none;">
                <div class="card-body text-color" >
                  <div class="d-flex align-items-center justify-content-md-center">
                    <div   class="icon">
                    <i class="fa fa-video-camera" aria-hidden="true"></i>
                    </div>
                    <h5>Video File</h5>
                  </div>
                  <h5>{{this.count}}</h5>
                </div>
                </router-link>
              </div>
              </div>
                <div class="col-md-6 col-lg-4 grid-margin stretch-card">
              <div class="card cards">
                  <router-link  class="menu-title " to="/pdf_list" style="text-decoration: none;">
                <div class="card-body text-color" >
                  <div class="d-flex align-items-center justify-content-md-center">
                    <div   class="icon">
                   <i class="fa fa-graduation-cap" aria-hidden="true"></i>
                    </div>
                    <h5>PDF File</h5>
                  </div>
                  <h5>{{this.pdfcount}}</h5>
                </div>
                </router-link>
              </div>
              </div>
               <div class="col-md-6 col-lg-4 grid-margin stretch-card">
              <div class="card cards">
                  <router-link  class="menu-title " to="/imagelist" style="text-decoration: none;">
                <div class="card-body text-color" >
                  <div class="d-flex align-items-center justify-content-md-center">
                    <div   class="icon">
                  <i class="fa fa-picture-o" aria-hidden="true"></i>
                    </div>
                    <h5>Images File</h5>
                  </div>
                  <h5>{{this.imagecount}}</h5>
                </div>
                </router-link>
              </div>
              </div> -->
          </div>
</template>
<script type="text/javascript">
import axios from 'axios'


export default{

 computed:{
  addminurl(){
    return this.$store.state.addminurl;

  },
  url(){
    return this.$store.state.url;
  }
  ,
  token(){
    return this.$store.state.authToken;
  },
  user(){
    return this.$store.state.user;
  },
},
data(){
  return{
   
   count:[], 
   pdfcount:[], 
   imagecount:[], 

   users:[],
  
 }
},
 mounted(){
  this.allvideoscount();
  this.allpdfcount();
  this.allimagecount();
  this.getallusers();
 },

methods:{
   getallusers(){
      axios.get('https://admin.rfrnd.com/api/getuser/',{
        headers: {
          Authorization:`Bearer ${this.token}`
        }
      })
      .then(response => {
        this.users=response.data;
        console.log('useers'+ this.users);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
   },
   allvideoscount(){
      axios.get(this.addminurl+'api/auth/getstudentscount/',{
        headers: {
          Authorization:`Bearer ${this.token}`
        }
      })
      .then(response => {
        this.count=response.data;
        console.log(this.count);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    },
       allpdfcount(){
      axios.get(this.addminurl+'api/auth/pdfcount/',{
        headers: {
          Authorization:`Bearer ${this.token}`
        }
      })
      .then(response => {
        this.pdfcount=response.data;
        console.log(this.pdfcount);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    },

      allimagecount(){
      axios.get(this.addminurl+'api/auth/pdfcount/',{
        headers: {
          Authorization:`Bearer ${this.token}`
        }
      })
      .then(response => {
        this.imagecount=response.data;
        console.log(this.imagecount);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    },

},
}
</script>
