<template>
	   <div class="row">
            <div class="col-md-6 col-lg-4 grid-margin stretch-card">
              <div class="card cards">
                  <router-link   class="menu-title " to="/users" style="text-decoration: none;">
                <div class="card-body text-color" >
                  <div class="d-flex align-items-center justify-content-md-center">
                    <div   class="icon">
                    <i class="fa fa-users" aria-hidden="true"></i>
                    </div>
                    <h5>Total User</h5>
                  </div>
                  <h5>{{this.totalstudentscount}}</h5>
                </div>
                </router-link>
              </div>
              </div>

<!--               <div class="col-md-6 col-lg-4 grid-margin stretch-card">
              <div class="card cards">
                  <router-link  class="menu-title " to="/videofile_list" style="text-decoration: none;">
                <div class="card-body text-color" >
                  <div class="d-flex align-items-center justify-content-md-center">
                    <div   class="icon">
                   <i class="fa fa-video-camera" aria-hidden="true"></i>
                    </div>
                    <h5>Video File</h5>
                  </div>
                  <h5>{{this.videocount}}</h5>
                </div>
                </router-link>
              </div>
              </div> -->
<!--               <div class="col-md-6 col-lg-4 grid-margin stretch-card">
              <div class="card cards">
                  <router-link  class="menu-title " to="/Imagefilelist" style="text-decoration: none;">
                <div class="card-body text-color" >
                  <div class="d-flex align-items-center justify-content-md-center">
                    <div   class="icon">
                  <i class="fa fa-picture-o" aria-hidden="true"></i>
                    </div>
                    <h5>Image File</h5>
                  </div>
                  <h5>{{this.imagecount}}</h5>
                </div>
                </router-link>
              </div>
              </div> -->
<!--                <div class="col-md-6 col-lg-4 grid-margin stretch-card">
              <div class="card cards">
                  <router-link  class="menu-title " to="/pdflist" style="text-decoration: none;">
                <div class="card-body text-color" >
                  <div class="d-flex align-items-center justify-content-md-center">
                    <div   class="icon">
                    <i class="fa fa-file" aria-hidden="true"></i>
                    </div>
                    <h5>PDF File</h5>
                  </div>
                  <h5>{{this.pdfcount}}</h5>
                </div>
                </router-link>
              </div>
              </div> -->
          </div>
</template>
<script type="text/javascript">
import axios from 'axios'
export default{
 computed:{
  addminurl(){
    return this.$store.state.addminurl;

  },
  url(){
    return this.$store.state.url;
  }
  ,
  token(){
    return this.$store.state.authToken;
  },
  user(){
    return this.$store.state.user;
  },
},
data(){
  return{
   
   videocount:[], 
   pdfcount:[], 
   imagecount:[], 
   totalstudentscount:[], 
  
 }
},
 mounted(){
  this.allStudentscount();
 },

methods:{
    allStudentscount(){
      axios.get(this.addminurl+'api/auth/getstudentscount',{
        headers: {
          Authorization:`Bearer ${this.token}`
        }
      })
      .then(response => {
        this.totalstudentscount=response.data;
        console.log(this.totalstudentscount);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    },


},
}
</script>
