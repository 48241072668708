<template>
      <div class="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center">
        <a class="navbar-brand brand-logo" href="index.html"><img src="images/logo.svg" alt="logo"/></a>
        <a class="navbar-brand brand-logo-mini" href="index.html"><img src="images/logo-mini.svg" alt="logo"/></a> 
      </div>
      <div class="navbar-menu-wrapper d-flex align-items-center">
        <button @click="minimizenavbar()" class="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
          <span class="icon-menu"></span>
        </button><br>
        <ul class="navbar-nav navbar-nav-right">
          <li class="nav-item dropdown" >
            <a class="nav-link count-indicator dropdown-toggle" id="notificationDropdown" href="#" data-toggle="dropdown">
              <i class="icon-bell mx-0"></i>
              <span class="count"></span>
            </a>
            <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="notificationDropdown" >
              <a class="dropdown-item">
                <p class="mb-0 font-weight-normal float-left">You have {{this.notice.length}} new notifications
                </p>
                <span class="badge badge-pill badge-warning float-right">View all</span>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item preview-item" v-for="notices in notice" :key="notices.id">
                <div class="preview-thumbnail">
                  <div class="preview-icon bg-info">
                    <i class="icon-envelope mx-0"></i>
                  </div>
                </div>
                <div class="preview-item-content" >
                  <h6 >{{notices.messages}}</h6>
                  <p class="font-weight-light small-text">
                    {{moment(notices.created_at).fromNow()}} 
                  </p>
                </div>
              </a>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link count-indicator dropdown-toggle" id="messageDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
              <i class="icon-envelope mx-0"></i>
              <span class="count"></span>
            </a>
            <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="messageDropdown">
              <div class="dropdown-item">
                <p class="mb-0 font-weight-normal float-left">You have 7 unread mails
                </p>
                <span class="badge badge-info badge-pill float-right">View all</span>
              </div>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item preview-item">
                <div class="preview-thumbnail">
                    <img src="images/faces/face4.jpg" alt="image" class="profile-pic">
                </div>
                <div class="preview-item-content flex-grow">
                  <h6 class="preview-subject ellipsis font-weight-medium">David Grey
                    <span class="float-right font-weight-light small-text">1 Minutes ago</span>
                  </h6>
                  <p class="font-weight-light small-text">
                    The meeting is cancelled
                  </p>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item preview-item">
                <div class="preview-thumbnail">
                    <img src="images/faces/face2.jpg" alt="image" class="profile-pic">
                </div>
                <div class="preview-item-content flex-grow">
                  <h6 class="preview-subject ellipsis font-weight-medium">Tim Cook
                    <span class="float-right font-weight-light small-text">15 Minutes ago</span>
                  </h6>
                  <p class="font-weight-light small-text">
                    New product launch
                  </p>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item preview-item">
                <div class="preview-thumbnail">
                    <img src="images/faces/face3.jpg" alt="image" class="profile-pic">
                </div>
                <div class="preview-item-content flex-grow">
                  <h6 class="preview-subject ellipsis font-weight-medium"> Johnson
                    <span class="float-right font-weight-light small-text">18 Minutes ago</span>
                  </h6>
                  <p class="font-weight-light small-text">
                    Upcoming board meeting
                  </p>
                </div>
              </a>
            </div>
          </li>
          <li class="nav-item nav-settings d-none d-lg-block">
            <a class="nav-link" href="#">
              <i class="icon-grid"></i>
            </a>
          </li>
        </ul>
        <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
          <span class="icon-menu"></span>
        </button>
      </div>
</template>
<script type="text/javascript">
import axios from 'axios';
import moment from 'moment';
export default{
 name:'StudentHeader',
 computed:{
  addminurl(){
    return this.$store.state.addminurl;

  },
  url(){
    return this.$store.state.url;
  }
  ,
  token(){
    return this.$store.state.authToken;
  },
  user(){
    return this.$store.state.user;
  },
},
data(){
  return{
   notice:[], 
   moment,
  
 }
},
 mounted(){
  this.noticeview();
  console.log(this.user);
 },

methods:{
   noticeview(){
      axios.get(this.addminurl+'api/auth/studentnotice/'+this.user.class_id,{
        headers: {
          Authorization:`Bearer ${this.token}`
        }
      })
      .then(response => {
        this.notice=response.data;
        console.log(this.notice);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    },
    minimizenavbar(){
    if (document.body.classList.contains('sidebar-icon-only')) {
      document.body.classList.remove('sidebar-icon-only');
     } else {
      document.body.classList.add('sidebar-icon-only');
     }
   }
  

},
}
</script>


