<template>
<component :is="layout">
    <router-view />
</component>
</template>
<script type="text/javascript">
import DefaultLayout from './layouts/DefaultLayout.vue';
import EmptyLayout from './layouts/EmptyLayout.vue';
  export default{
    components: {
     DefaultLayout,
     EmptyLayout
    },
    computed:{
      layout() {
       const layout = this.$route.meta.layout || 'default';
       return layout === 'empty' ? 'EmptyLayout' : 'DefaultLayout';
      }

    },
  }
</script>
